import React, { useEffect, useRef } from "react";
import { BlogEditOptions } from "../BlogEditOptions/BlogEditOptions";
import { useParams } from "react-router-dom";
import { useAxios } from "../../../hooks";
import { useEditBlogContent } from "../../../stores";

export const BlogSingleOverview = () => {
	const { id } = useParams();
	const { data, loading, error } = useAxios({ API: `blog/${id}` });
	const { setBlogContent } = useEditBlogContent();
	const contentRef = useRef(null);

	useEffect(() => {
		if (data && data.entryContent) {
			const decodedContent = decodeHtmlEntities(data.entryContent);
			contentRef.current.innerHTML = decodedContent;
		}
	}, [data]);

	const handleContentChange = () => {
		const newContent = contentRef.current.innerHTML;
		const cleanedContent = removeEmptyElements(newContent);
		setBlogContent(cleanedContent);
	};

	const decodeHtmlEntities = (text) => {
		const textArea = document.createElement("textarea");
		textArea.innerHTML = text;
		return textArea.value;
	};

	const removeEmptyElements = (html) => {
		const tempDiv = document.createElement("div");
		tempDiv.innerHTML = html;
		const elements = tempDiv.getElementsByTagName("*");
		for (let i = elements.length - 1; i >= 0; i--) {
			const element = elements[i];
			if (!element.textContent.trim() && !element.querySelector("img")) {
				element.parentNode.removeChild(element);
			}
		}
		return tempDiv.innerHTML;
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				height: "100%",
			}}
		>
			<BlogEditOptions data={data} />
			{loading && <div>Loading...</div>}
			{error && <div>Error: {error}</div>}
			<div
				style={{
					margin: "2rem",
					padding: "2rem",
					height: "100%",
					width: "100%",
				}}
				contentEditable
				onBlur={handleContentChange}
				ref={contentRef}
				dangerouslySetInnerHTML={{ __html: data?.entryContent ?? "" }}
			/>
		</div>
	);
};
