import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AxiosDelete } from "../../../handler";
import { PortfolioOptions } from "../PortfolioOptions/PortfolioOptions";
import { ImageRemovable } from "../PortfolioImages/ImageRemovable";

import "../Portfolio.style.scss";

export const PortfolioSingleOverview = (props) => {
	const { displayData } = props;
	const [images, setImages] = useState([]);

	useEffect(() => {
		setImages(displayData?.images);
	}, [displayData]);

	const { id } = useParams();

	const handleDeleteImage = (imageID) => {
		const confirmDelete = window.confirm(`Dieses Bild aus dem Portfolio entfernen?`);
		if (confirmDelete) {
			AxiosDelete(`portfolio/image/${id}/${imageID}`);
			window.location.reload();
		}
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				height: "100%",
			}}
		>
			<PortfolioOptions data={displayData} />
			<div className="portfolio-grid portfolio-gap-2">
				{images?.map((data, key) => {
					return (
						<ImageRemovable
							key={key}
							data={data}
							handleDeleteImage={() => handleDeleteImage(data._id)}
						/>
					);
				})}
			</div>
		</div>
	);
};
