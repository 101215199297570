import { useLocation, useSearchParams } from "react-router-dom";
import "./View.style.scss";

export const View = (props) => {
	const { children } = props;

	const location = useLocation();

	const title = location.pathname.split("/")[1];

	const [searchParams] = useSearchParams();
	const action = searchParams.get("action");
	const subtitle = action?.length ? action : null;
	const selectedName = searchParams.get("name");

	return (
		<div className="wrapper">
			<div className="content-title-wrapper">
				<div className="content-title-container">
					<div className="content-title">
						{title}
						{selectedName !== null ? `: ${selectedName}` : null}
					</div>
					<div className="content-title-parallelogram "></div>
				</div>
				<div className="content-subtitle">
					{subtitle?.length ? "-- " : null}
					{subtitle}
				</div>
			</div>

			<div className="content-container">{children}</div>
		</div>
	);
};
