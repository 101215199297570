import { firebaseAuthApp } from "../../../utils/firebase";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Select } from "../../Layout/Select/Select";
import { Input } from "../../Layout/Input/Input";
import { BiTrashAlt, BiSave } from "react-icons/bi";
import { AxiosDelete } from "../../../handler";
import { useThumbnailStore } from "../../../stores";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import "./PortfolioOptions.styles.scss";

const SERVER = process.env.REACT_APP_SERVER;

export const PortfolioOptions = (props) => {
	const { data } = props;
	const { _id } = data;

	const location = useLocation();
	const isCreatePage = location.pathname.includes("portfolio/create");

	const titleRef = useRef(null);
	const categoryRef = useRef(null);
	const activeRef = useRef(null);
	const portfolioIDRef = useRef(null);

	const [files, setFiles] = useState([]);
	const { thumbnail, setThumbnail } = useThumbnailStore();

	useEffect(() => {
		setThumbnail(data.thumbnail);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const handleDeletePortfolio = (portfolioID) => {
		const confirmDelete = window.confirm(`${data.title} wirklich löschen?`);
		if (confirmDelete) {
			AxiosDelete(`portfolio/${portfolioID}`);
			navigate("/portfolio");
		}
	};

	const handleFileChange = (e) => {
		if (e.target.files) {
			const fileList = Array.from(e.target.files);
			setFiles(fileList);
		}
	};

	const handeFileDeleteFromList = (index) => {
		setFiles((prevFiles) => {
			const updatedFiles = [...prevFiles];
			updatedFiles.splice(index, 1);
			return updatedFiles;
		});
	};

	const navigate = useNavigate();
	const { id } = useParams();

	const handleOnCreate = async () => {
		const token = await firebaseAuthApp.currentUser?.getIdToken();

		const AxiosHeader = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const title = titleRef.current?.value;
		const categories = categoryRef.current?.value.replace(/\s*,\s*/g, ",");
		const active = activeRef.current?.value;
		const portfolioID = portfolioIDRef.current?.value;

		if (!title || !categories) {
			alert("Titel und Kategorie müssen ausgefüllt sein!");
			return;
		}

		const formData = new FormData();
		formData.append("title", title);
		formData.append("portfolioID", portfolioID);
		formData.append("categories", categories);
		formData.append("active", active);
		formData.append("thumbnail", thumbnail);

		files.forEach((file, index) => {
			formData.append("files", file);
		});

		if (id) {
			axios.patch(`${SERVER}/portfolio/${id}`, formData, AxiosHeader);
			window.location.reload();
			return;
		}

		axios
			.post(`${SERVER}/portfolio/`, formData, AxiosHeader)
			.then((response) => {
				console.log("Portfolio created:", response.data);
				navigate("/portfolio");
			})
			.catch((error) => {
				console.error("Error creating portfolio:", error);
			});
	};

	return (
		<div className="option-bar">
			<div
				className="thumbnail"
				style={{
					backgroundImage: `url(${
						files.length > 0 ? URL.createObjectURL(files[0]) : thumbnail
					})`,
					backgroundSize: "cover",
				}}
			></div>

			{id !== undefined ? null : (
				<Input
					label="Porfolio-ID (kan nicht mehr geändert werden)"
					value=""
					ref={portfolioIDRef}
				/>
			)}

			<Input label="Title" value={data.title || ""} ref={titleRef} />
			<Input label="Categories" value={data.category || ""} ref={categoryRef} />
			<Select label="Sichtbarkeit" active={data.active} ref={activeRef} />

			<div className="form-group">
				<input type="file" name="files" onChange={handleFileChange} multiple />
			</div>

			<div className="file-list">
				{files.map((file, index) => (
					<div className="file-list-item" key={index}>
						<span>
							{`${file.name.substring(0, 16)}${file.name.length > 16 ? "..." : ""} - ${
								file.type
							}`}
						</span>
						<button onClick={() => handeFileDeleteFromList(index)}>
							<BiTrashAlt />
						</button>
					</div>
				))}
			</div>

			<button className="update-btn" onClick={() => handleOnCreate()}>
				<BiSave />
				{isCreatePage ? "Save" : "Update"}
			</button>
			{_id && (
				<button className="delete-btn" onClick={() => handleDeletePortfolio(_id)}>
					<BiTrashAlt /> Delete
				</button>
			)}
		</div>
	);
};
