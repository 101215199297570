import { create } from "zustand";

const localStorageKey = "authStore";

const initialState = (() => {
	const savedState = localStorage.getItem(localStorageKey);
	if (savedState) {
		return JSON.parse(savedState);
	}
	return { user: { name: "Engin", loggedIn: false } };
})();

export const useAuthStore = create((set) => ({
	user: initialState.user,
	setUser: (user) => {
		set({ user });
		localStorage.setItem(localStorageKey, JSON.stringify({ user }));
	},
}));

export default useAuthStore;
