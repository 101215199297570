import { firebaseAuthApp } from "../../utils/firebase";

import { View, Box, Input, Button } from "../../components/Layout";

import { useAuthStore } from "../../stores";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const LoginView = () => {
	const { setUser } = useAuthStore();
	const navigate = useNavigate();

	useEffect(() => {
		firebaseAuthApp.onAuthStateChanged((user) => {
			if (user !== null) {
				setUser({ name: user.displayName, loggedIn: true });
				navigate("/", { replace: true });
			} else {
				setUser({ name: "", loggedIn: false });
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLogin = useCallback(async (event) => {
		event.preventDefault();

		const { email, password } = event.currentTarget;

		try {
			await firebaseAuthApp.signInWithEmailAndPassword(email.value, password.value);
		} catch (error) {
			alert(error);
		}
	}, []);

	return (
		<View>
			<Box flex center fullWidth fullHeight style={{ border: "none" }}>
				<Box style={{ maxWidth: "600px" }}>
					<form onSubmit={handleLogin}>
						<Input name="email" type="email" placeholder="Email" />
						<Input name="password" type="password" placeholder="Password" />
						<Button label="Login" type="submit" />
					</form>
				</Box>
			</Box>
		</View>
	);
};
