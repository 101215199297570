import React, {
	useRef,
	useImperativeHandle,
	forwardRef,
	useEffect,
	useState,
} from "react";
import "./Select.style.scss";

export const Select = forwardRef((props, ref) => {
	const { label, active } = props;
	const selectRef = useRef(null);
	const [isReady, setIsReady] = useState(false);

	useImperativeHandle(ref, () => selectRef.current);

	useEffect(() => {
		if (active !== undefined) {
			setIsReady(true);
		}
	}, [active]);

	const selectedValue = active === true ? "1" : "0";

	if (!isReady) {
		return (
			<div className="form-group">
				<label>{label}</label>

				<select className="select" defaultValue={1} ref={selectRef}>
					<option value="1">Aktiv</option>
					<option value="0">Inaktiv</option>
				</select>
			</div>
		);
	}

	return (
		<div className="form-group">
			<label>{label}</label>

			<select className="select" defaultValue={selectedValue} ref={selectRef}>
				<option value="1">Aktiv</option>
				<option value="0">Inaktiv</option>
			</select>
		</div>
	);
});
