import { View } from "../../components/Layout";
import { BlogSingleOverview } from "../../components/Blog";

export const BlogPostView = () => {
	return (
		<View>
			<BlogSingleOverview />
		</View>
	);
};
