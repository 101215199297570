import { create } from "zustand";

export const useOptionsStore = create((set) => ({
	site: "",
	setSite: (site) => {
		set({ site: site });
	},
	title: "",
	setTitle: (title) => {
		set({ title: title });
	},
}));
