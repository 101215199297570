import React from "react";

export const ImageRemovable = (props) => {
	const { data, handleDeleteImage } = props;

	return (
		<div className="portfolio-image-container">
			<img src={data.image} className="portfolio-rounded" alt="nothing" width="100%" />
			<div className="portfolio-hover-content">
				<button className="portfolio-title" onClick={handleDeleteImage}>
					Bild Löschen
				</button>
			</div>
		</div>
	);
};
