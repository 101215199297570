import { useNavigate } from "react-router-dom";
import { AddButton } from "../../Layout";
import "./BlogOverview.style.scss";

export const BlogOverview = (props) => {
	const { displayData } = props;
	const navigate = useNavigate();

	const handleOnClick = (blogID) => {
		navigate(`/blog/${blogID}`);
	};

	const handleOnCreate = () => {
		navigate(`/blog/create`);
	};

	return (
		<div className="blog-grid blog-gap-2">
			{displayData.map((data, key) => {
				return (
					<div
						key={key}
						className="blog-image-container"
						onClick={() => handleOnClick(data._id)}
					>
						<img
							src={data.entryImage}
							className="blog-rounded"
							alt="nothing"
							width="100%"
						/>
						<div className="blog-hover-content">
							<div className="blog-title">{data.entryTitle}</div>
							<div className="blog-category">
								{data.entryCategories.map((cat) => cat.toUpperCase()).join(", ")}
							</div>
						</div>
					</div>
				);
			})}
			<AddButton size="4rem" onClick={() => handleOnCreate()} height="250px" />
		</div>
	);
};
