import { LoginView, LogoutView, WebsiteView, ErrorPageView } from "../pages";
import { useRoutes, Navigate } from "react-router-dom";
import { PorfolioRoutes, BlogRoutes } from "./sub-routes";
import { useAuthStore } from "../stores";

const privateRoutes = [
	{
		path: "*",
		element: <LoginView />,
	},
];

const routes = [
	{
		path: "/",
		element: <Navigate to="/portfolio" replace={true} />,
	},
	{
		path: "portfolio",
		label: "Portfolio",
		children: PorfolioRoutes,
	},
	{
		path: "blog",
		label: "Blog",
		children: BlogRoutes,
	},
	{
		path: "website",
		label: "Website",
		element: <WebsiteView />,
	},
	{
		path: "auth",
		element: <LoginView />,
	},
	{
		path: "logout",
		element: <LogoutView />,
		label: "Abmelden",
	},
	{
		path: "*",
		element: <ErrorPageView />,
	},
];

const RoutesContext = () => {
	const { user } = useAuthStore();
	const { loggedIn } = user;

	const routing = useRoutes(loggedIn ? routes : privateRoutes);
	return <>{routing}</>;
};

export { routes, RoutesContext };
