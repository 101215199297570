import { useState, useEffect, useRef } from "react";
import { AxiosDelete } from "../../../handler";
import { ImageRemovable } from "../PortfolioImages/ImageRemovable";
import { AddButton } from "../../Layout";
import axios from "axios";
import { firebaseAuthApp } from "../../../utils/firebase";

import "./WebsiteOverview.style.scss";

const SERVER = process.env.REACT_APP_SERVER;

export const WebsiteOverview = (props) => {
	const { displayData } = props;
	const [isUploading, setIsUploading] = useState(false);
	const fileInputRef = useRef(null);

	useEffect(() => {
		if (isUploading) {
			handleUpload();
		}
	}, [isUploading]);

	const handleDelete = (imageID) => {
		AxiosDelete(`website/${imageID}`);
		window.location.reload();
	};

	const handleUpload = async () => {
		const token = await firebaseAuthApp.currentUser?.getIdToken();

		const AxiosHeader = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "multipart/form-data",
			},
		};

		const file = fileInputRef.current.files[0];

		const formData = new FormData();
		formData.append("files", file);

		axios
			.post(`${SERVER}/website/`, formData, AxiosHeader)
			.then(() => {
				setIsUploading(false);
				window.location.reload();
			})
			.catch((error) => {
				console.error("Error uploading image:", error);
				setIsUploading(false);
			});
	};

	const handleAddButtonClicked = () => {
		fileInputRef.current.click();
	};

	return (
		<div className="website-grid">
			{displayData.map((data, key) => {
				return (
					<ImageRemovable
						key={key}
						data={data}
						handleDeleteImage={() => handleDelete(data._id)}
					/>
				);
			})}
			{displayData.length < 16 && (
				<div>
					<input
						type="file"
						accept="image/*"
						ref={fileInputRef}
						style={{ display: "none" }}
						onChange={() => setIsUploading(true)}
					/>
					<AddButton onClick={handleAddButtonClicked} />
				</div>
			)}
		</div>
	);
};
