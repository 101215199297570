import React from "react";
import { useThumbnailStore } from "../../../stores";

export const ImageRemovable = (props) => {
	const { data, handleDeleteImage } = props;
	const { setThumbnail } = useThumbnailStore();

	return (
		<div className="portfolio-image-container">
			<img src={data.url} className="portfolio-rounded" alt="nothing" width="100%" />
			<div className="portfolio-hover-content">
				<button
					className="portfolio-title"
					onClick={() => {
						setThumbnail(data.url);
					}}
				>
					Als Thumbnail
				</button>
				<button className="portfolio-title" onClick={handleDeleteImage}>
					Bild Löschen
				</button>
			</div>
		</div>
	);
};
