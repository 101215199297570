import "./Box.styles.scss";

export const Box = (props) => {
	const { children, style, flex, center, fullWidth, fullHeight } = props;

	const flexStyle = flex ? { display: "flex" } : {};
	const centerStyle = center ? { justifyContent: "center", alignItems: "center" } : {};
	const fullWidthStyle = fullWidth ? { width: "100%" } : {};
	const fullHeightStyle = fullHeight ? { height: "100%" } : {};

	const combinedStyle = {
		...flexStyle,
		...centerStyle,
		...fullWidthStyle,
		...fullHeightStyle,
		...style,
	};

	return (
		<div className="box" style={combinedStyle}>
			{children}
		</div>
	);
};
