import axios from "axios";
import { firebaseAuthApp } from "../utils/firebase";

export const AxiosDelete = async (API) => {
	const token = await firebaseAuthApp.currentUser?.getIdToken();
	const SERVER = process.env.REACT_APP_SERVER;

	try {
		await axios.delete(`${SERVER}/${API}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (error) {
		console.log(error);
	}
};
