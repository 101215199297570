import { Outlet } from "react-router-dom";
import { View } from "../../components/Layout";
import { useAxios } from "../../hooks";
import { PortfolioOverview } from "../../components/Portfolio";

export const PortfolioAllView = () => {
	const { data, loading, error } = useAxios({ API: "portfolio" });

	return (
		<View>
			{loading && <div>Loading...</div>}
			{error && <div>Error: {error}</div>}
			<PortfolioOverview displayData={data} />
			<Outlet />
		</View>
	);
};
