import { useNavigate } from "react-router-dom";
import { AddButton } from "../../Layout";
import { ImageWithTitle } from "../PortfolioImages/ImageWithTitle";

import "../Portfolio.style.scss";

export const PortfolioOverview = (props) => {
	const { displayData } = props;

	const navigate = useNavigate();

	const handleOnClick = (portfolioID, portfolioName) => {
		navigate(`/portfolio/${portfolioID}?name=${portfolioName}`);
	};

	const handleOnCreate = () => {
		navigate(`/portfolio/create`);
	};

	return (
		<div
			className="portfolio-grid portfolio-gap-2"
			style={{
				gridTemplateColumns: `repeat(6, 1fr)`,
			}}
		>
			{displayData.map((images, key) => {
				const { _id, thumbnail, title, category } = images;

				return (
					<ImageWithTitle
						key={key}
						data={{ _id, thumbnail, title, category }}
						handleOnClick={handleOnClick}
					/>
				);
			})}
			<AddButton size="4rem" onClick={() => handleOnCreate()} />
		</div>
	);
};
