import { firebaseAuthApp } from "../../../utils/firebase";
import axios from "axios";
import { useRef, useState } from "react";
import { Input } from "../../Layout/Input/Input";
import { BiTrashAlt, BiSave } from "react-icons/bi";
import { AxiosDelete } from "../../../handler";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEditBlogContent } from "../../../stores";

import "./BlogOptions.styles.scss";

const SERVER = process.env.REACT_APP_SERVER;
const allowedExtensions = ["jpeg", "jpg", "png"];

export const BlogEditOptions = (props) => {
	const { data } = props;
	const { _id } = data;
	const { content } = useEditBlogContent();

	const location = useLocation();
	const isCreatePage = location.pathname.endsWith("/create");
	const [thumbnailFile, setThumbnailFile] = useState(null); // State to store the selected file
	const [uploadProgress, setUploadProgress] = useState(0); // State to store the upload progress

	const titleRef = useRef(null);
	const shortDescriptionRef = useRef(null);
	const dateRef = useRef(null);
	const categoryRef = useRef(null);

	const handleImageSelect = (event) => {
		const file = event.target.files[0];
		const reader = new FileReader();

		const fileExtension = file.name.split(".").pop().toLowerCase();

		if (!allowedExtensions.includes(fileExtension)) {
			alert("Only image files with jpeg/jpg/png extension are allowed!");
			return;
		}

		reader.onload = (e) => {
			setThumbnailFile(file);
		};

		reader.readAsDataURL(file);
	};

	const handleDeletePortfolio = (blogID) => {
		const confirmDelete = window.confirm(`${data.title} wirklich löschen?`);
		if (confirmDelete) {
			AxiosDelete(`blog/${blogID}`);
			navigate("/blog");
		}
	};

	const navigate = useNavigate();
	const { id } = useParams();

	const handleOnCreate = async () => {
		const token = await firebaseAuthApp.currentUser?.getIdToken();

		const AxiosHeader = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			onUploadProgress: (progressEvent) => {
				const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
				setUploadProgress(progress);
			},
		};

		const title = titleRef.current?.value;
		const shortDescription = shortDescriptionRef.current?.value;
		const date = dateRef.current?.value;
		const categories = categoryRef.current?.value.replace(/\s*,\s*/g, ",");

		if (!thumbnailFile) {
			console.error("No thumbnail image selected");
			return;
		}

		const fileExtension = thumbnailFile.name.split(".").pop().toLowerCase();

		if (!allowedExtensions.includes(fileExtension)) {
			alert("Only image files with jpeg/jpg/png extension are allowed!");
			return;
		}

		const formData = new FormData();
		formData.append("title", title);
		formData.append("shortDescription", shortDescription);
		formData.append("entryContent", content);
		formData.append("date", date);
		formData.append("categories", categories);
		const timestamp = new Date().getTime();
		const fileName = `thumbnail_${timestamp}.${fileExtension}`;

		formData.append("thumbnail", thumbnailFile, fileName);

		if (isCreatePage) {
			axios
				.post(`${SERVER}/blog/`, formData, AxiosHeader)
				.then((response) => {
					console.log("Blog entry created:", response.data);
					navigate("/blog");
				})
				.catch((error) => {
					console.error("Error creating blog entry:", error);
				});
		} else {
			axios
				.put(`${SERVER}/blog/${id}`, formData, AxiosHeader)
				.then((response) => {
					console.log("Portfolio created:", response.data);
					navigate("/blog");
				})
				.catch((error) => {
					console.error("Error updating portfolio:", error);
				});
		}
	};

	return (
		<div className="blog-option-bar" style={{ paddingTop: "4rem", paddingLeft: "1rem" }}>
			<input
				type="file"
				accept="image/*"
				onChange={handleImageSelect}
				style={{ marginBottom: "1rem" }}
			/>
			{uploadProgress > 0 && <div>{uploadProgress}% uploaded</div>}
			<Input label="Title" value={data.title || ""} ref={titleRef} />
			<Input
				label="Short Description"
				value={data.shortDescription || ""}
				ref={shortDescriptionRef}
			/>

			<Input label="Date" value={data.date || ""} ref={dateRef} type="date" />
			<Input label="Categories" value={data.category || ""} ref={categoryRef} />

			<button className="update-btn" onClick={handleOnCreate}>
				<BiSave />
				{isCreatePage ? "Save" : "Update"}
			</button>

			{!isCreatePage && (
				<button className="delete-btn" onClick={() => handleDeletePortfolio(_id)}>
					<BiTrashAlt /> Delete
				</button>
			)}
		</div>
	);
};
