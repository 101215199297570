import { firebaseAuthApp } from "../../utils/firebase";
import { View } from "../../components/Layout";
import { useAuthStore } from "../../stores";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const LogoutView = () => {
	const { setUser } = useAuthStore();
	const navigate = useNavigate();

	useEffect(() => {
		firebaseAuthApp.signOut();
		setUser({ name: "", loggedIn: false });
		navigate("/login", { replace: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<View>
			<>Ciao</>
		</View>
	);
};
