import { Link } from "react-router-dom";
import { useOptionsStore } from "../../../stores";

export const NavigationItem = (props) => {
	const { path, label } = props;
	const { setSite } = useOptionsStore();

	const handleOnClick = (setPath) => {
		setSite(setPath);
	};

	return (
		<Link to={path} onClick={() => handleOnClick(path)}>
			<div className="navigation-item" style={path === "logout" ? { opacity: ".4" } : {}}>
				{label}
			</div>
		</Link>
	);
};
