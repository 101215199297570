import { BrowserRouter, Outlet } from "react-router-dom";
import { RoutesContext } from "./routes";
import { Navigation } from "./components/Layout";

function App() {
	return (
		<BrowserRouter>
			<RoutesContext />
			<Outlet />
			<Navigation />
		</BrowserRouter>
	);
}

export default App;
