import { useState, useEffect } from "react";
import axios from "axios";

export const useAxios = (props) => {
	const SERVER = process.env.REACT_APP_SERVER;
	const { API } = props;
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");

	const fetchData = async () => {
		try {
			const response = await axios.get(`${SERVER}/${API}`);
			setData(response.data);
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Add refetch function
	const refetch = () => {
		setLoading(true);
		setError("");
		fetchData();
	};

	return { data, loading, error, refetch };
};
