import { Outlet } from "react-router-dom";
import { View } from "../../components/Layout";
import { useAxios } from "../../hooks";
import { WebsiteOverview } from "../../components/Website";

export const WebsiteView = () => {
	const { data, loading, error } = useAxios({ API: "website" });

	return (
		<View>
			{loading && <div>Loading...</div>}
			{error && <div>Error: {error}</div>}
			<WebsiteOverview displayData={data} />
			<Outlet />
		</View>
	);
};
