import React from "react";

export const ImageWithTitle = (props) => {
	const { data, handleOnClick } = props;

	return (
		<div
			className="portfolio-image-container"
			onClick={() => handleOnClick(data._id, data.title)}
		>
			<img
				src={data.thumbnail}
				className="portfolio-rounded"
				alt="nothing"
				width="100%"
			/>
			<div className="portfolio-hover-content">
				<div className="portfolio-title">{data.title}</div>
				<div className="portfolio-category">
					{data.category.map((cat) => cat.toUpperCase()).join(", ")}
				</div>
			</div>
		</div>
	);
};
