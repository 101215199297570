import { Outlet } from "react-router-dom";
import { useAxios } from "../../hooks";
import { View } from "../../components/Layout";
import { BlogOverview } from "../../components/Blog";

export const BlogView = () => {
	const { data, loading, error } = useAxios({ API: "blog" });

	return (
		<View>
			{loading && <div>Loading...</div>}
			{error && <div>Error: {error}</div>}
			<BlogOverview displayData={data} />
			<Outlet />
		</View>
	);
};
