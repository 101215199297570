import { View } from "../../components/Layout";
import { useAxios } from "../../hooks";
import { useParams } from "react-router-dom";
import { PortfolioSingleOverview } from "../../components/Portfolio";

export const PortfolioSingleView = () => {
	const { id } = useParams();
	const { data, loading, error } = useAxios({ API: `portfolio/${id}` });

	return (
		<View>
			{loading && <div>Loading...</div>}
			{error && console.log(error)}
			<PortfolioSingleOverview displayData={data} />
		</View>
	);
};
