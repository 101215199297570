import { Navigate } from "react-router-dom";
import { BlogView, BlogPostView } from "../../pages";
import { BlogCreateOverview } from "../../components/Blog/BlogCreateOverview/BlogCreateOverview";

export const BlogRoutes = [
	{
		path: "",
		element: <BlogView />,
	},
	{
		path: ":id",
		element: <BlogPostView />,
	},
	{
		path: "create",
		element: <BlogCreateOverview />,
	},
	{
		path: "*",
		element: <Navigate to="/blog" replace={true} />,
	},
];
