import React, { useRef } from "react";
import { BlogEditOptions } from "../BlogEditOptions/BlogEditOptions";
import { useEditBlogContent } from "../../../stores";

export const BlogCreateOverview = () => {
	const { setBlogContent } = useEditBlogContent();
	const contentRef = useRef(null);

	const handleContentChange = () => {
		const newContent = contentRef.current.innerHTML;
		const cleanedContent = removeEmptyElements(newContent);
		setBlogContent(cleanedContent);
	};

	const removeEmptyElements = (html) => {
		const tempDiv = document.createElement("div");
		tempDiv.innerHTML = html;
		const elements = tempDiv.getElementsByTagName("*");
		for (let i = elements.length - 1; i >= 0; i--) {
			const element = elements[i];
			if (!element.textContent.trim() && !element.querySelector("img")) {
				element.parentNode.removeChild(element);
			}
		}
		return tempDiv.innerHTML;
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				height: "100%",
			}}
		>
			<BlogEditOptions data={[]} />
			<div
				style={{
					margin: "2rem",
					padding: "2rem",
					height: "100%",
					width: "100%",
				}}
				contentEditable
				onBlur={handleContentChange}
				ref={contentRef}
				dangerouslySetInnerHTML={{ __html: "" }}
			/>
		</div>
	);
};
