import { Navigate } from "react-router-dom";
import { PortfolioAllView, PortfolioSingleView } from "../../pages";

export const PorfolioRoutes = [
	{
		path: "",
		element: <PortfolioAllView />,
	},
	{
		path: ":id",
		element: <PortfolioSingleView />,
	},
	{
		path: "create",
		element: <PortfolioSingleView />,
	},
	{
		path: "*",
		element: <Navigate to="/portfolio" replace={true} />,
	},
];
