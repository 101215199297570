import React from "react";
import image from "../../../assets/image/empty.png";

export const AddButton = (props) => {
	const { onClick, size, height } = props;

	return (
		<div
			className="addbutton-image-container"
			style={{ backgroundColor: "rgb(90 90 90)" }}
			onClick={onClick}
		>
			<img
				src={image}
				className="portfolio-rounded"
				alt="nothing"
				width="100%"
				style={{ maxHeight: height }}
			/>
			<div className="addbutton-hover-content">
				<div
					className="addbutton-title"
					style={{
						fontSize: size,
					}}
				>
					+
				</div>
			</div>
		</div>
	);
};
