import { NavigationItem } from "./NavigationItem";
import { routes } from "../../../routes";
import { useAuthStore } from "../../../stores";

import "./Navigation.styles.scss";

export const Navigation = () => {
	const { user } = useAuthStore();
	const { loggedIn } = user;

	const navLinks = routes
		.filter((route) => route.label !== undefined)
		.map((route) => {
			return <NavigationItem key={route.path} path={route.path} label={route.label} />;
		});

	return (
		<div className="navigation-wrapper">
			<div className="navigation-container">
				<a href="https://justengin.de" target="_blank" rel="noreferrer">
					<div className="navigation-title">
						Just <br /> Engin
					</div>
				</a>
				{loggedIn ? (
					<div className="navigation">{navLinks}</div>
				) : (
					<div className="navigation">
						<NavigationItem
							path="/auth"
							label="Willkommen, Log dich ein um weiterzumachen."
						/>
					</div>
				)}
			</div>
		</div>
	);
};
