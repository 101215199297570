import React, { useRef, useImperativeHandle, forwardRef } from "react";
import "./Input.styles.scss";

export const Input = forwardRef((props, ref) => {
	const { name, label, placeholder, type, value } = props;
	const inputRef = useRef(null);

	useImperativeHandle(ref, () => inputRef.current);

	return (
		<div className="form-group">
			<label>{label?.toLocaleUpperCase()}</label>
			<input
				name={name}
				type={type ?? "text"}
				placeholder={placeholder}
				defaultValue={value ?? ""}
				ref={inputRef}
			/>
		</div>
	);
});
